// src/components/TireList.tsx
import React, { useState, useEffect } from "react";
import TireBrandDropdown from "../components/TireBrandDropdown";
import axios from "axios";
import { BrandOption } from "../types";
import TireSizeDropdown from "../components/TireSizeDropdowns";
import { defaultTire, Tire } from "./Tire";
import TirePrintButton from "../components/TirePrintButton";
import TireConditionDropdown from "../components/TireConditionDropdown";

const TireList: React.FC = () => {
  const [tires, setTires] = useState<Tire[]>([]);
  const [newTire, setNewTire] = useState<Tire>(defaultTire);

  const handleTireSizeChange = (updatedSize: {
    width: string;
    ratio: string;
    diameter: string;
  }) => {
    setNewTire((prev) => ({ ...prev, ...updatedSize }));
  };
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tireCondition, setTireCondition] = useState("");

  // Set the base URL globally
  axios.defaults.baseURL = "https://mts-tools.com";

  useEffect(() => {
    // Fetch tires from the backend when the component mounts
    fetchTires();
  }, []);

  const handleConditionSelect = (condition: any) => {
    setTireCondition(condition);
  };

  const fetchTires = async () => {
    try {
      const response = await axios.get("/api/Tires");
      setTires(response.data);
    } catch (error) {
      console.error("Error fetching tires:", error);
    }
  };

  const addTire = async () => {
    try {
      newTire.condition = tireCondition;

      // Ensure that width, ratio, and diameter are not empty
      if (!newTire.width || !newTire.ratio || !newTire.diameter) {
        console.error("Width, ratio, and diameter are required.");
        return;
      }

      if (newTire.condition === "") {
        newTire.condition = "New";
      }

      // Set the size property based on width, ratio, and diameter
      const newSize = `${newTire.width}/${newTire.ratio}/${newTire.diameter}`;

      // Send the new tire object with the correct size property
      const response = await axios.post("/api/tires", {
        ...newTire,
        size: newSize,
        quantity: 1,
      });

      // Update the tires state with the new tire
      setTires([...tires, response.data]);

      // Reset all dropdown fields to their initial state
      setNewTire(defaultTire);
      setTireCondition("");
      handleTireBrandSelect(null);
    } catch (error) {
      console.error("Error adding tire:", error);
    }
  };
  const removeTire = async (id: number) => {
    try {
      const response = await axios.get(`/api/tires/${id}`);
      const removedTire = response.data;

      if (removedTire.quantity > 1) {
        // If quantity is greater than 1, decrement the quantity
        const updatedTires = tires.map((tire) =>
          tire.id === id ? { ...tire, quantity: tire.quantity - 1 } : tire
        );

        setTires(updatedTires);
      } else {
        // If quantity is 1 or less, remove the tire from the list
        const updatedTires = tires.filter((tire) => tire.id !== id);
        setTires(updatedTires);
      }

      // Update the backend to decrement the quantity
      await axios.delete(`/api/tires/${id}`);
    } catch (error) {
      console.error("Error removing tire:", error);
    }
  };

  // Add default values or empty strings based on your requirements
  const handleTireBrandSelect = (selectedBrand: BrandOption | null) => {
    setNewTire((prev) => ({
      ...prev,
      brand: selectedBrand ? selectedBrand.value : "",
    }));
  };

  const filteredTires = tires.filter((tire) => {
    const tireSize = `${tire.width}/${tire.ratio}/${tire.diameter}`;

    return (
      tire.brand.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tireSize.includes(searchQuery.toLowerCase())
    );
  });

  const increaseTireQuantity = async (tire: Tire) => {
    try {
      // Ensure that width, ratio, and diameter are not empty
      if (!tire.width || !tire.ratio || !tire.diameter) {
        console.error("Width, ratio, and diameter are required.");
        return;
      }

      // Send the tire object with the correct size property
      const response = await axios.post("/api/tires", tire);

      // Update the tires state with the updated tire
      setTires((prevTires) => {
        // Replace the existing tire with the updated one
        const updatedTires = prevTires.map((t) =>
          t.id === response.data.id ? response.data : t
        );
        return updatedTires;
      });
    } catch (error) {
      console.error("Error increasing tire quantity:", error);
    }
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        color: "#333",
        padding: "20px",
      }}
    >
      <h2 style={{ color: "Black" }}>Tire Inventory</h2>
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ padding: "8px", marginBottom: "16px" }}
      />
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px" }}
      >
        <thead>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Brand</th>
            <th style={tableHeaderStyle}>Size</th>
            <th style={tableHeaderStyle}>Quantity</th>
            <th style={tableHeaderStyle}>Condition</th>
            <th style={tableHeaderStyle}>Application</th>
            <th style={tableHeaderStyle}>Price</th>
            <th style={tableHeaderStyle}>Description</th>

            <th style={tableHeaderStyle}>Actions</th>
            <th style={tableHeaderStyle}>Print Label</th>
          </tr>
        </thead>
        <tbody>
          {filteredTires.map((tire) => (
            <tr key={tire.id}>
              <td style={tableCellStyle}>{tire.id}</td>
              <td style={tableCellStyle}>{tire.brand}</td>
              <td
                style={tableCellStyle}
              >{`${tire.width}/${tire.ratio}/${tire.diameter}`}</td>
              <td style={tableCellStyle}>{tire.quantity}</td>
              <td style={tableCellStyle}>{tire.condition}</td>

              <td style={tableCellStyle}>
                <button onClick={() => removeTire(tire.id)}>-1</button>
                <button onClick={() => increaseTireQuantity(tire)}>+1</button>
              </td>
              <td style={tableCellStyle}>
                <TirePrintButton tireInfo={tire} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3 style={{ color: "black", marginTop: "16px" }}>Add New Tire</h3>
      <TireBrandDropdown onSelect={handleTireBrandSelect} />

      <TireSizeDropdown
        tireSize={newTire}
        onTireSizeChange={handleTireSizeChange}
      />

      <TireConditionDropdown
        tire={newTire}
        onSelectCondition={handleConditionSelect}
      />

      <div
        style={{
          color: "#fff",
          paddingTop: "16px",
        }}
      >
        <button
          onClick={addTire}
          style={{
            backgroundColor: "yellow",
            color: "black",
            padding: "8px 16px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          disabled={
            newTire.diameter === "" ||
            newTire.width === "" ||
            newTire.ratio === ""
          }
        >
          Add Tire
        </button>
      </div>
    </div>
  );
};

const tableHeaderStyle: React.CSSProperties = {
  backgroundColor: "yellow",
  color: "black",
  padding: "12px",
  textAlign: "left",
};

const tableCellStyle: React.CSSProperties = {
  borderBottom: "1px solid #ddd",
  padding: "10px",
};

export default TireList;

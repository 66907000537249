import React from "react";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import { Tire } from "../model/Tire";

interface TirePrintButtonProps {
  tireInfo: Tire; // Specify the prop type
}

const generatePdf = async (tireInfo: Tire) => {
  try {
    // Create a new PDF document with dimensions 4 inches by 6 inches
    const pdf = new jsPDF({
      unit: "in",
      format: [4, 6],
    });

    // Add tire information to the PDF
    pdf.text(`Brand: ${tireInfo.brand}`, 0.5, 0.5).setFontSize(32);
    pdf
      .text(`${tireInfo.width}/${tireInfo.ratio}/${tireInfo.diameter}`, 0.5, 5)
      .setFontSize(14);

    pdf.text(`MTS MobileTyre`, 0.5, 0.9).setFontSize(14);
    pdf.text(`${tireInfo.condition}`, 0.5, 1.1).setFontSize(14);

    let qrData = JSON.stringify(tireInfo);

    // Generate a QR code for the tire information
    const qrCodeDataUrl = await QRCode.toDataURL(qrData);

    // Add the QR code to the PDF
    pdf.addImage(qrCodeDataUrl, "JPEG", 0.5, 1.3, 3, 3);

    // Save or open the PDF
    pdf.save(
      `${tireInfo.brand}-${tireInfo.width}-${tireInfo.ratio}-${tireInfo.diameter}.pdf`
    );
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

const TirePrintButton: React.FC<TirePrintButtonProps> = ({
  tireInfo: Tire,
}) => {
  const handlePrint = () => {
    generatePdf(Tire);
  };

  return <button onClick={handlePrint}>Print QR Code</button>;
};

export default TirePrintButton;

export interface Tire {
  id: number;
  brand: string;
  width: string;
  ratio: string;
  diameter: string;
  quantity: number;
  condition: string;
}

export const defaultTire: Tire = {
  id: 0,
  brand: "",
  width: "",
  ratio: "",
  diameter: "",
  quantity: 0,
  condition: "",
};

// tireBrands.tsx

export const allTireBrands = [
  // Global Brands
  { value: "michelin", label: "Michelin" },
  { value: "goodyear", label: "Goodyear" },
  { value: "bridgestone", label: "Bridgestone" },
  { value: "continental", label: "Continental" },
  { value: "pirelli", label: "Pirelli" },
  { value: "hankook", label: "Hankook" },
  { value: "cooper", label: "Cooper" },
  { value: "firestone", label: "Firestone" },
  { value: "falken", label: "Falken" },
  { value: "nitto", label: "Nitto" },
  { value: "nokian", label: "Nokian" },
  { value: "toyo", label: "Toyo" },
  { value: "sumitomo", label: "Sumitomo" },
  { value: "bf_goodrich", label: "B.F. Goodrich" },
  { value: "kumho", label: "Kumho" },
  { value: "general_tire", label: "General Tire" },
  { value: "federal", label: "Federal" },
  { value: "yokohama", label: "Yokohama" },
  { value: "nexen", label: "Nexen" },
  { value: "maxxis", label: "Maxxis" },
  { value: "gt_radial", label: "GT Radial" },
  { value: "kenda", label: "Kenda" },
  { value: "mickey_thompson", label: "Mickey Thompson" },
  { value: "nankang", label: "Nankang" },
  { value: "kelly_tires", label: "Kelly Tires" },
  { value: "uniroyal", label: "Uniroyal" },
  { value: "solideal", label: "Solideal" },
  { value: "bkt", label: "BKT" },
  { value: "hercules", label: "Hercules" },
  { value: "achilles_radial", label: "Achilles Radial" },
  { value: "westlake", label: "Westlake" },
  { value: "roadstone", label: "Roadstone" },
  { value: "laufenn", label: "Laufenn" },
  { value: "atturo", label: "Atturo" },
  // Add more tire brands
];

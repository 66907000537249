export const ratioOptions = [
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
];

// Convert to the format { value: string; label: string; }
const convertedRatioOptions = ratioOptions.map((value) => ({
  value,
  label: value,
}));

export default convertedRatioOptions;

// TireBrandDropdown.tsx

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { allTireBrands } from "../model/tireBrands";
import { BrandOption } from "../types";

interface TireBrand {
  value: string;
  label: string;
}

interface TireBrandDropdownProps {
  onSelect: (selectedBrand: BrandOption | null) => void;
}

const TireBrandDropdown: React.FC<TireBrandDropdownProps> = ({ onSelect }) => {
  const [brands, setBrands] = useState<TireBrand[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<TireBrand | null>(null);

  useEffect(() => {
    setBrands(allTireBrands);
  }, []);

  const handleBrandSelect = (selectedBrand: BrandOption | null) => {
    onSelect(selectedBrand);
    setSelectedBrand(selectedBrand);
  };

  return (
    <Select
      options={brands}
      value={selectedBrand}
      onChange={(selectedOption) => handleBrandSelect(selectedOption)}
      isSearchable
      placeholder="Select a tire brand..."
    />
  );
};

export default TireBrandDropdown;

// TireSizeDropdown.tsx
import React from "react";
import Select from "react-select";
import widthOptions from "../model/widthOptions";
import convertedRatioOptions from "../model/ratioOptions";
import convertedDiameterOptions from "../model/diameterOptions";

interface TireSizeDropdownProps {
  tireSize: { width: string; ratio: string; diameter: string };
  onTireSizeChange: (updatedSize: {
    width: string;
    ratio: string;
    diameter: string;
  }) => void;
}

const TireSizeDropdown: React.FC<TireSizeDropdownProps> = ({
  tireSize,
  onTireSizeChange,
}) => {
  const handleWidthChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    onTireSizeChange({
      width: selectedOption?.value || "",
      ratio: tireSize.ratio,
      diameter: tireSize.diameter,
    });
  };

  const handleRatioChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    onTireSizeChange({
      width: tireSize.width,
      ratio: selectedOption?.value || "",
      diameter: tireSize.diameter,
    });
  };

  const handleDiameterChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    onTireSizeChange({
      width: tireSize.width,
      ratio: tireSize.ratio,
      diameter: selectedOption?.value || "",
    });
  };

  return (
    <div>
      <label>
        Width:
        <Select
          options={widthOptions}
          value={{ value: tireSize.width, label: tireSize.width }}
          onChange={handleWidthChange}
        />
      </label>
      <label>
        Ratio:
        <Select
          options={convertedRatioOptions}
          value={{ value: tireSize.ratio, label: tireSize.ratio }}
          onChange={handleRatioChange}
        />
      </label>
      <label>
        Diameter:
        <Select
          options={convertedDiameterOptions}
          value={{ value: tireSize.diameter, label: tireSize.diameter }}
          onChange={handleDiameterChange}
        />
      </label>
    </div>
  );
};

export default TireSizeDropdown;

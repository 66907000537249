import React, { useState, ChangeEvent } from "react";
import { Tire } from "../model/Tire";

interface TireConditionDropdownProps {
  tire: Tire;
  onSelectCondition: (condition: string) => void;
}

const TireConditionDropdown: React.FC<TireConditionDropdownProps> = ({
  tire,
  onSelectCondition,
}) => {
  const [selectedCondition, setSelectedCondition] = useState(
    tire.condition || ""
  );

  const handleConditionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const condition = event.target.value;
    setSelectedCondition(condition);
    onSelectCondition(condition);
  };

  return (
    <div>
      <label htmlFor="tireCondition">Tire Condition:</label>
      <select
        id="tireCondition"
        value={selectedCondition}
        onChange={handleConditionChange}
      >
        <option value="">Select Condition</option>
        <option value="new">New</option>
        <option value="used">Used</option>
      </select>
    </div>
  );
};

export default TireConditionDropdown;

export const widthOptions = [
  "135",
  "145",
  "155",
  "165",
  "175",
  "185",
  "195",
  "205",
  "215",
  "225",
  "235",
  "245",
  "255",
  "265",
  "275",
  "285",
  "295",
  "305",
  "315",
  "325",
  "335",
  "345",
  "355",
  "365",
  "375",
  "385",
  "395",
  "405",
  "415",
  "425",
  "435",
  "445",
  "455",
  "465",
  "475",
  "485",
  "495",
];

// Convert to the format { value: string; label: string; }
const convertedWidthOptions = widthOptions.map((value) => ({
  value,
  label: value,
}));

export default convertedWidthOptions;

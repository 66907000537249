// src/App.tsx
import React, { useState } from "react";
import Login from "./components/Login";
import TireList from "./model/TireList";

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  return (
    <div className="App">
      {!loggedIn ? <Login onLogin={handleLogin} /> : <TireList />}
    </div>
  );
};

export default App;
